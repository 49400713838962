import styles from '../styles/admin.module.css';
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import 'moment/locale/fr';

import { UserContext } from '../Contexts/UserContext';

export default function Admin() {

  const {uid} = useContext(UserContext);
  const [authPassed, setAuthPassed] = useState(false);
  const [pass, setPass] = useState("");
  const [passError, setPassError] = useState(false);
  const [articles, setArtciles] = useState([]);

  const login = ()=>{
    if(pass === "younes1234"){
      setAuthPassed(true);
      setPassError(false);
    }else{
      setPassError(true);
    }
  }

  const getData = ()=>{
    const db = firebase.database().ref("articles");
    db.once('value',function(snap){
      let retArray = [];
      if(snap.val){
        snap.forEach(function(child){
          let items = child.val();
          retArray.push(items);
        })
      }
      setArtciles(retArray.reverse());
    })
  }

  const deleteArticle = (id)=>{
    var result = window.confirm("êtes-vous sûr de vouloir supprimer cet article");
    if (result) {
      firebase.database().ref("articles/"+id).remove().then(()=> getData() );
    }
  }

  const articleContainer = (item)=>(
    <div className={styles.article} key={item.id} >
      <div className={styles.articleImgContainer} >
        <img src={item.picture} className={styles.articleImg} alt='article background' />
      </div>
      <div className={styles.articleBodyContainer} >
        <div >
          <p className={styles.articleDate} >{moment(item.date).format('LLL')}</p>
          <p className={styles.articleTitle} >{item.title}</p>
        </div>
        <div >
          <Link to={`/edit-article/${item.id}`} state={{ fromAdmin: true }} className={styles.editBtn} >Editer</Link>
          <button className={styles.deleteBtn} onClick={()=> deleteArticle(item.id) } >Supprimer</button>
        </div>
      </div>
    </div>
  )

  useEffect(()=>{
    if(uid){
      getData();
    }
  },[uid])

  if(!authPassed){
    return(
      <div className={styles.admin} >
        <p className={styles.adminPassTitle} >Admin</p>
        {passError && <p className={styles.adminPassError} >Mot de passe incorrect</p>}
        <div className={styles.adminPassInputs} >
          <input
            value={pass}
            onChange={(e)=> setPass(e.target.value) }
            placeholder='Password'
            className={styles.adminPassInput}
          />
          <button className={styles.adminPassBtn} onClick={()=> login() } >
            Se connecter
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.admin} >
      <div className={styles.adminHeader} >
        <span className={styles.adminTitle} >Admin</span>
        <Link to='/add-article' state={{ fromAdmin: true }} className={styles.adminPassBtn} >
          Nouveau article
        </Link>
      </div>
      {articles.map((it)=> articleContainer(it) )}
    </div>
  )
}
