import React,{ createContext, useState, useEffect } from "react";
import axios from "axios";
import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import { loginEvent } from "./events";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {

  const [uid, setUid] = useState("");

  const signInAnonymously = ()=>{
    firebase.auth().signInAnonymously()
    .then(() => {
      console.log("Signed in");
    })
    .catch((error) => {
      var errorMessage = error.message;
      console.log(errorMessage);
    });
  }

  useEffect(()=>{
    const listener = firebase.auth().onAuthStateChanged(user => {
      if(user){
        localStorage.setItem('uid', user?.uid);
        setUid(user?.uid);
      }
    });
    return () => {
      listener();
    };
  },[])

  useEffect(() => {
    let storageUid = localStorage.getItem('uid');
    if (storageUid){
      setUid(storageUid);
    }else{
      signInAnonymously();
    }
  }, []);

  const values = {
    uid
  }

  return (
    <UserContext.Provider value={values}>
      {children}
    </UserContext.Provider>
  );
};