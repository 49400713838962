import styles from '../styles/article.module.css'
import React, {useState, useEffect, useContext} from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import 'moment/locale/fr';
import { FiChevronLeft } from 'react-icons/fi';

import { UserContext } from '../Contexts/UserContext';

export default function Article() {

  const {uid} = useContext(UserContext);
  const navigate = useNavigate();
  const {articleId} = useParams();
  const [article, setArticle] = useState({});

  const getArticle = ()=>{
    const db = firebase.database().ref("articles/"+articleId); 
    db.once('value',function(snap){
      if(snap.val){
        setArticle(snap.val());
      }
    })
  } 

  useEffect(()=>{
    if(uid){
      getArticle();
    }
  },[uid])

  return (
    <div className={styles.articleContainer} >
      <div className={styles.article} >
        <div className={styles.articleHead} >
          <FiChevronLeft size={28} className={styles.backBtn} onClick={()=> navigate("/articles") } />
          <p className={styles.date} >{moment(article.date).format('Do MMMM YYYY, H:mm')}</p>
        </div>
        <p className={styles.title} >{article.title}</p>
        <div className={styles.imgContainer} >
          <img src={article.picture} className={styles.img} alt='article background' />
        </div>
        <div className={styles.text} >
          {ReactHtmlParser(article.text)}
        </div>
      </div>
    </div>
  )
}
