import styles from '../styles/addArticle.module.css'; 
import React, { useState, useEffect } from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import firebase from 'firebase/app';
import 'firebase/database';
import "firebase/storage";
import { useNavigate, useLocation } from "react-router-dom";
import cx from 'classnames';

const config = {
  toolbar: {
    items: [
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      'indent',
      'outdent',
      '|',
      'heading',
      '|',
      'undo',
      'redo',
      '|',
      'link',
      'insertTable'
    ]
  }
}

const TextEditor = ({ onSubmit }) => {
  let navigate = useNavigate(); 
  const location = useLocation();

  const [bodyType, setBodyType] = useState("text");
  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const [imageAsFile, setImageAsFile] = useState('');
  const [imageAsUrl, setImageAsUrl] = useState({imgUrl: ''});

  const storage = firebase.storage();

  ClassicEditor.defaultConfig = config;

  const handleFireBaseUpload = e => {
    e.preventDefault()
    console.log('start of upload')
    // async magic goes here...
    if(imageAsFile === '') {
      console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
    }
    const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
    //initiates the firebase side uploading 
    uploadTask.on('state_changed', 
    (snapShot) => {
      //takes a snap shot of the process as it is happening
      console.log(snapShot)
    }, (err) => {
      //catches the errors
      console.log(err)
    }, () => {
      // gets the functions from storage refences the image storage in firebase by the children
      // gets the download url then sets the image from firebase as the value for the imgUrl key:
      storage.ref('images').child(imageAsFile.name).getDownloadURL()
      .then(fireBaseUrl => {
        setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}));
        setImageAsFile("");
      })
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ body });
    const post = firebase.database().ref("articles/").push();
    var pId = post.key ;
    post.set({
      id: pId,
      title,
      text: body,
      date: new Date().getTime(),
      picture: imageAsUrl.imgUrl
    }).then(()=>{
      console.log("posted");
      navigate('/');
    })
  }
  
  useEffect(()=>{
    if(location.state?.fromAdmin){
      console.log("from admin");
    }else{
      navigate('/');
    }
  },[location])

  return (
    // <form onSubmit={handleSubmit} >
    <div >
      <input
        onChange={(event)=> setTitle(event.target.value) }
        value={title}
        placeholder="Titre de l'article"
        className={styles.newArticleTitleInput}
      />
      <div className={styles.imgInput} >
        { imageAsUrl.imgUrl.length === 0 &&
          <div style={{display: 'flex', justifyContent: 'space-between'}} >
            <input
              label="Image"
              placeholder="Choose image"
              accept="image/png,image/jpeg"
              type="file"
              onChange={(e) => {
                setImageAsFile(e.target.files[0]);
              }}
            />
            <button className={styles.uploadImgBtn} onClick={(e)=> handleFireBaseUpload(e) } >upload image</button>
          </div>
        }
        {imageAsFile !== '' && <img alt="preview" src={URL.createObjectURL(imageAsFile)} className={styles.imgPreview} />}
        {imageAsUrl.imgUrl.length > 0 && <img alt="preview" src={imageAsUrl.imgUrl} className={styles.imgPreview} /> }
      </div>
      <div style={{display: 'flex', justifyContent: 'center'}} >
        <div className={styles.articleBodyTypeWrapper} >
          <button onClick={()=> setBodyType("text") } className={cx(styles.articleBodyTypeBtn, {[styles.activeBodyTypeBtn]: bodyType === "text" })} >Text</button>
          <button onClick={()=> setBodyType("html") } className={cx(styles.articleBodyTypeBtn, {[styles.activeBodyTypeBtn]: bodyType === "html" })} >Html</button>
        </div>
      </div>
      {
        bodyType === "text" ?
        <CKEditor
          editor={ClassicEditor}
          onChange={(event, editor) => {
            const data = editor.getData();
            setBody(data)
          }}
        />
        :
        <textarea
          className={styles.htmlTextArea}
          rows={4}
          placeholder='Poster votre text html ici ...'
          onChange={(event) => setBody(event.target.value) }
        />
      }
      <div className={styles.submitBtnContainer} >
        <button type='submit' className={styles.submitBtn} onClick={(e)=> handleSubmit(e) } >Enregistrer</button>
      </div>
    </div>
  )
}

export default function AddArticle() {
  return (
    <div className={styles.addArticle} >
      <TextEditor/>
    </div>
  )
}
