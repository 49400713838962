import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBSUuiIQJtaIcv_0SRCaVqAAkdiPjcBerE",
  authDomain: "betterfly-site.firebaseapp.com",
  projectId: "betterfly-site",
  storageBucket: "betterfly-site.appspot.com",
  messagingSenderId: "265680388502",
  appId: "1:265680388502:web:871ae156316cb3d8475db7",
  measurementId: "G-CLRRFKLRXS",
  databaseURL: "https://betterfly-site-default-rtdb.firebaseio.com/",
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
