import '../styles/main.css';
import React, {useState, useEffect, useMemo, useRef, useContext} from 'react';
import Marquee from "react-fast-marquee";
import cx from 'classnames';
import { FiArrowRight } from 'react-icons/fi';
import ReactHtmlParser from 'react-html-parser';
import {Helmet} from "react-helmet";

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import moment from 'moment';
import 'moment/locale/fr';

import { UserContext } from '../Contexts/UserContext';

import ContactUsModal from './ContactUsModal';
import img1 from '../Images/img1.png';
import img2 from '../Images/img2.png';
import img3 from '../Images/img3.png';
import pcImg from '../Images/pcImg.png';
import phoneImg from '../Images/phoneImg.png';

import connectivity from '../Images/connectivity2.png'
import visualisation from '../Images/Visualisation.jpg';
import automatisation from '../Images/Automatisation.png';
import ai from '../Images/ai.png';

const prev_menu = [
  {id:0, name: "Tableau de bord"},
  {id:1, name: "Plans d'actions"},
  {id:2, name: "Management des risques"},
  {id:3, name: "Objectifs & Indicateurs"},
  {id:4, name: "Analyse SWOT & BPI"},
  {id:5, name: "Administration"},
  {id:6, name: 'Voir plus'},
];

const prevMenu_pcImgs_2 = [
  {id: 1, nom: "Tableau de bord", type: "video", url: "https://res.cloudinary.com/m-l-e/video/upload/v1692201486/betterfly/home.mp4"},
  {id: 2, nom: "Plans d'actions", type: "video", url: "https://res.cloudinary.com/m-l-e/video/upload/v1693598958/betterfly/action.mov"},
  {id: 3, nom: "Management des risques", type: "video", url: "https://res.cloudinary.com/m-l-e/video/upload/v1692204713/betterfly/risque.mov"},
  {id: 4, nom: "Objectifs & Indicateurs", type: "video", url: "https://res.cloudinary.com/m-l-e/video/upload/v1692205783/betterfly/objectif.mov"},
  {id: 5, nom: "Analyse SWOT & BPI", type: "img", url: "https://res.cloudinary.com/m-l-e/image/upload/v1692183711/betterfly/swot.png"},
  {id: 6, nom: "Administration", type: "img", url: "https://res.cloudinary.com/m-l-e/image/upload/v1692183072/betterfly/admin.png"},
];
const prevMenu_phoneImgs_2 = [
  {id: 1, nom: "Tableau de bord", type: "video", url: "https://res.cloudinary.com/m-l-e/video/upload/v1691757076/betterfly/home_phone.mov"},
  {id: 2, nom: "Plans d'actions", type: "video", url: "https://res.cloudinary.com/m-l-e/video/upload/v1693598952/betterfly/action_phone.mov"},
  {id: 3, nom: "Management des risques", type: "video", url: "https://res.cloudinary.com/m-l-e/video/upload/v1691757248/betterfly/risque_phone.mov"},
  {id: 4, nom: "Objectifs & Indicateurs", type: "video", url: "https://res.cloudinary.com/m-l-e/video/upload/v1691757391/betterfly/objectif_phone.mov"},
  {id: 5, nom: "Analyse SWOT & BPI", type: "img", url: "https://res.cloudinary.com/m-l-e/image/upload/v1692183858/betterfly/swot_phone.png"},
  {id: 6, nom: "Administration", type: "img", url: "https://res.cloudinary.com/m-l-e/image/upload/v1691758622/betterfly/admin_phone.png"},
]

const prevMenu_pcImgs = {
  "Tableau de bord": "",
  "Plans d'actions": "https://res.cloudinary.com/m-l-e/image/upload/v1687205193/betterfly/ptopdcg1gmxwbh7ufdoi.png",
  "Management des risques": "https://res.cloudinary.com/m-l-e/image/upload/v1687205194/betterfly/fdkaixp4hqpz9i4lwyoe.png",
  "Objectifs & Indicateurs": "https://res.cloudinary.com/m-l-e/image/upload/v1687205196/betterfly/uztp4mhwrmrfophsc2cl.png",
  "Analyse SWOT & BPI": "https://res.cloudinary.com/m-l-e/image/upload/v1691703781/betterfly/swot.png",
  "Administration": "https://res.cloudinary.com/m-l-e/image/upload/v1691704751/betterfly/admin.png"
};
const prevMenu_phoneImgs = {
  "Plans d'actions": "https://res.cloudinary.com/m-l-e/image/upload/v1687357245/betterfly/zktps00djiqsgivlxwp1.png",
  "Management des risques": "https://res.cloudinary.com/m-l-e/image/upload/v1687357014/betterfly/meafvrz47cypjwf0pbmt.png",
  "Objectifs & Indicateurs": "https://res.cloudinary.com/m-l-e/image/upload/v1687357019/betterfly/jryq08sfmfxna3w4z9zk.png",
  "Analyse SWOT & BPI": "https://res.cloudinary.com/m-l-e/image/upload/v1687357019/betterfly/sezkdsv7ukm1mxnkutoi.png",
  "Administration": "https://res.cloudinary.com/m-l-e/image/upload/v1687357001/betterfly/xawez5unrc1njj89ntsq.png"
}

const features = [
  {id:1, title: "Analyse de Contexte", text: "Mieux comprendre le contexte de votre entreprise pour une gestion QSE plus pertinente et ciblée."},
  {id:2, title: "Création des Processus", text: "Créez et gérez vos processus de manière intuitive et efficace, ce qui permet une meilleure organisation et une plus grande visibilité."},
  {id:3, title: "Management des Risques", text: "Identifiez et gérez les risques de manière proactive pour une meilleure performance QSE."},
  {id:4, title: "Gestion des Plans d'Actions", text: "Suivez et contrôlez efficacement les plans d'actions de votre système de management QSE."},
  {id:5, title: "Gestion de la Santé & Sécurité au Travail", text: "Assurez une gestion adéquate de la santé et de la sécurité au travail pour maintenir un environnement de travail sûr et productif."},
  {id:6, title: "Gestion des Aspects Environnementaux", text: "Gardez le contrôle des impacts environnementaux de votre entreprise grâce à une gestion efficace des aspects environnementaux."},
  {id:7, title: "Gestion des Audits", text: "Planifiez et suivez vos audits avec facilité, garantissant une conformité continue."},
  {id:8, title: "Gestion Automatisée des Documents", text: "Gardez vos documents organisés et facilement accessibles grâce à la gestion automatisée des documents."},
  {id:9, title: "Gestion des Revues de Direction", text: "Betterfly facilite la préparation et le suivi des revues de direction, vous permettant de vous concentrer sur les décisions stratégiques."},
]

const references = [
  {id: 1, name: "marsa maroc", year: "2019 - 2023", iso: "ISO 9001 - ISO 45001 - ISO 14001", img: 'https://res.cloudinary.com/m-l-e/image/upload/v1689516124/betterfly/fvmdrxr7mqgbbcwjb3fd.png'},
  {id: 2, name: "entd", year: "2019", iso: "ISO 9001", img: 'https://media.licdn.com/dms/image/C560BAQF1Cuc2DpXkkg/company-logo_100_100/0/1621440272615?e=2147483647&v=beta&t=axZSR1BaLOQoI-CA0ZwFyhQViK_xg-Yt1pgPIkOz7r4'},
  {id: 3, name: "valoplast", year: "2023", iso: "ISO 9001 - BRC Packaging", img: 'https://valoplast.com/images/logo.jpg'},
  {id: 4, name: "ch", year: "2023", iso: "ISO 9001", img: 'https://res.cloudinary.com/m-l-e/image/upload/v1691770610/betterfly/gtp8eq3fijmqyepiwwbe.jpg'},
  {id: 5, name: "pechnor", year: "2023", iso: "ISO 9001", img: 'https://res.cloudinary.com/m-l-e/image/upload/v1691770710/betterfly/x3rjphybzscfpwgfdgc8.jpg'},
  {id: 6, name: "adeauplast", year: "2023", iso: "ISO 9001", img: 'https://adeauplast.com/wp-content/uploads/2017/08/adeauplast-logo-stickyx2.png'},
  {id: 7, name: "Expand Consulting", year: "2020", iso: "ISO 21001", img: 'https://media.licdn.com/dms/image/C4D0BAQGN5nnOkb-FVw/company-logo_200_200/0/1545146082317?e=2147483647&v=beta&t=iEnflyaQ9SH0-pUV5AiyA13fLiN2iDjd6xqNZ2t-Awo'},
  {id: 8, name: "Adecco", year: "2022", iso: "ISO 9001 - ISO 14001", img: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/Adecco_Logo.svg/1200px-Adecco_Logo.svg.png'},
  {id: 9, name: "bolton food", year: "2022 - 2023", iso: "ISO 45001", img: 'https://dsy29q8p25pv9.cloudfront.net/fooddata/Media/000/000/004/403_L.png'},
]

function FadeInSection(props) {
  const [isVisible, setVisible] = useState(false);
  const domRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setVisible(entry.isIntersecting));
    });
    observer.observe(domRef.current);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

function articleContainer(item, index){

  // let text = (item.text).split("</p>")[0]+"</p>";

  const enterArticleBtn = (index)=>{
    document.getElementById("articleBtnText"+index).classList.add("activeMainArticleBtnText");
  }
  const leaveArticleBtn = (index)=>{
    document.getElementById("articleBtnText"+index).classList.remove("activeMainArticleBtnText");
  }

  let text = item.text.substring(0, 150);
  text = text.replaceAll('<h2>', ' ');
  text = text.replaceAll('</h2>', ' ');
  text = text.replace('<strong>', ' ');
  text = text.replace('</strong>', ' ');
  text = text.replace('<p>', ' ');
  text = text.replace('</p>', ' ');
  text = text.replace('<h1>', ' ');
  text = text.replace('</h1>', ' ');
  text = text.replace('&nbsp;', '');

  return (
    <div className='main_article' key={item.id} >
      <img src={item.picture} className='main_articleImg' alt='article background' />
      <div className='main_articleTitle' >
        <span >{item.title}</span>
      </div>
      <div className='main_articleText' >
        {text}
      </div>
      <div style={{display: 'flex', justifyContent: 'space-between'}} >
        <span className='main_articleDate' >{moment(item.date).format('LLL')}</span>
      </div>
      <a href={`/article/${item.id}`} className='main_articleBtn' onMouseEnter={()=> enterArticleBtn(index) } onMouseLeave={()=> leaveArticleBtn(index) } >
        <span id={'articleBtnText'+index} className='main_articleBtnText' >Lire plus</span>
        <FiArrowRight size={18} />
      </a>
    </div>
  )
}
export default function Main() {

  const {uid} = useContext(UserContext);
  const [selectedCat, setSelectedCat] = useState("Tableau de bord");
  const [selectedCatIndex, setSelectedCatIndex] = useState(0);
  const [imgLoad, setImgLoad] = useState(false);
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [articles, setArtciles] = useState([]);

  const openModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsModalOpen(true);
  }
  const closeModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsModalOpen(false);
  }

  const selectCat = (name, index)=>{
    setSelectedCat(name);
    setSelectedCatIndex(index);
    setImgLoad(false);
  }
  const selectCatMobile = (name)=>{
    switch (name) {
      case "Tableau de bord":
        setSelectedCat(name);
        setSelectedCatIndex(0);
        setImgLoad(false);
        break;
      case "Plans d'actions":
        setSelectedCat(name);
        setSelectedCatIndex(1);
        setImgLoad(false);
        break;
      case "Management des risques":
        setSelectedCat(name);
        setSelectedCatIndex(2);
        setImgLoad(false);
        break;
      case "Objectifs & Indicateurs":
        setSelectedCat(name);
        setSelectedCatIndex(3);
        setImgLoad(false);
        break;
      case "Analyse SWOT & BPI":
        setSelectedCat(name);
        setSelectedCatIndex(4);
        setImgLoad(false);
        break;
      case "Administration":
        setSelectedCat(name);
        setSelectedCatIndex(5);
        setImgLoad(false);
        break;
      default:
        break;
    }
  }

  const getData = ()=>{
    const db = firebase.database().ref("articles");
    db.limitToLast(3).once('value',function(snap){
      let retArray = [];
      if(snap.val){
        snap.forEach(function(child){
          let items = child.val();
          retArray.push(items);
        })
      }
      setArtciles(retArray.reverse());
    })
  }

  useEffect(()=>{
    if(uid){
      getData();
    }
  },[uid])

  const ref1_top = useRef(null);
  const ref1_bottom = useRef(null);
  const ref2_top = useRef(null);
  const ref2_bottom = useRef(null);
  const ref3_top = useRef(null);
  const ref3_bottom = useRef(null);
  const ref4_top = useRef(null);
  const ref4_bottom = useRef(null);

  const isInViewport1_top = useIsInViewport(ref1_top);
  const isInViewport1_bottom = useIsInViewport(ref1_bottom);
  const isInViewport2_top = useIsInViewport(ref2_top);
  const isInViewport2_bottom = useIsInViewport(ref2_bottom);
  const isInViewport3_top = useIsInViewport(ref3_top);
  const isInViewport3_bottom = useIsInViewport(ref3_bottom);
  const isInViewport4_top = useIsInViewport(ref4_top);
  const isInViewport4_bottom = useIsInViewport(ref4_bottom);

  useEffect(()=>{
    if(isInViewport1_top && isInViewport1_bottom){
      document.getElementById("advantageImg1").classList.add("showImg");
      document.getElementById("advantageImg2").classList.contains("showImg") && document.getElementById("advantageImg2").classList.remove("showImg");
      document.getElementById("advantageImg3").classList.contains("showImg") && document.getElementById("advantageImg3").classList.remove("showImg");
      document.getElementById("advantageImg4").classList.contains("showImg") && document.getElementById("advantageImg4").classList.remove("showImg");
    }
  },[isInViewport1_top, isInViewport1_bottom]);
  useEffect(()=>{
    if(isInViewport2_top && isInViewport2_bottom){
      document.getElementById("advantageImg2").classList.add("showImg");
      document.getElementById("advantageImg1").classList.contains("showImg") && document.getElementById("advantageImg1").classList.remove("showImg");
      document.getElementById("advantageImg3").classList.contains("showImg") && document.getElementById("advantageImg3").classList.remove("showImg");
      document.getElementById("advantageImg4").classList.contains("showImg") && document.getElementById("advantageImg4").classList.remove("showImg");
    }
  },[isInViewport2_top, isInViewport2_bottom]);
  useEffect(()=>{
    if(isInViewport3_top && isInViewport3_bottom){
      document.getElementById("advantageImg3").classList.add("showImg");
      document.getElementById("advantageImg1").classList.contains("showImg") && document.getElementById("advantageImg1").classList.remove("showImg");
      document.getElementById("advantageImg2").classList.contains("showImg") && document.getElementById("advantageImg2").classList.remove("showImg");
      document.getElementById("advantageImg4").classList.contains("showImg") && document.getElementById("advantageImg4").classList.remove("showImg");
    }
  },[isInViewport3_top, isInViewport3_bottom]);
  useEffect(()=>{
    if(isInViewport4_top && isInViewport4_bottom){
      document.getElementById("advantageImg4").classList.add("showImg");
      document.getElementById("advantageImg1").classList.contains("showImg") && document.getElementById("advantageImg1").classList.remove("showImg");
      document.getElementById("advantageImg2").classList.contains("showImg") && document.getElementById("advantageImg2").classList.remove("showImg");
      document.getElementById("advantageImg3").classList.contains("showImg") && document.getElementById("advantageImg3").classList.remove("showImg");
    }
  },[isInViewport4_top, isInViewport4_bottom]);

  // const signInAnonymously = ()=>{
  //   firebase.auth().signInAnonymously()
  //   .then(() => {
  //     console.log("Signed in");
  //   })
  //   .catch((error) => {
  //     var errorMessage = error.message;
  //     console.log(errorMessage);
  //   });
  // }

  // useEffect(() => {
  //   const listener = firebase.auth().onAuthStateChanged(user => {
  //     if(user){
  //       console.log(user?.uid);
  //     }else{
  //       signInAnonymously();
  //     }
  //   });
  //   return () => {
  //     listener();
  //   };
  // }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="http://www.betterfly.com/articles" />

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-725193655"></script>
        <script type="text/javascript">{`          
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-725193655');
        `}</script>
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": "Aperçu de Betterfly",
            "description": "Betterfly : Solution de Digitalisation QSE (Optimisez Qualité, Sécurité et Environnement)",
            "thumbnailUrl": [
              "https://res.cloudinary.com/m-l-e/image/upload/v1694170626/betterfly/home_img.png"
            ],
            "uploadDate": "2023-09-08T10:59:04+0000",
            "contentUrl": "https://res.cloudinary.com/m-l-e/video/upload/v1692201486/betterfly/home.mp4"
          }
        `}</script>
      </Helmet>
      <div className="firstB block" id='home' >
        <h1 className="main_title" >
          Votre Allié pour un Système de Management QSE Intuitif et Intelligent
        </h1>
        <h3 className='main_subTitle' >
          Exploitez le potentiel de l'intelligence artificielle pour améliorer votre Management QSE 
        </h3>
        <div className='firstB_btns' >
          <a href='https://calendly.com/younes-laassouli/demonstration-betterfly' target='_blank' rel="noreferrer" className="mainBookBtn">
            <span>Rendez-vous en ligne</span>
          </a>
          <span className='firstB_orSeparator' >ou</span>
          <button className='main_inputBtn' onClick={()=> openModal()} >
            <span>Me Contacter</span>
          </button>
          {/* <div className='main_inputContainer' >
            <input 
              className='main_input'
              type="text"
              placeholder="name@company.com" 
              value={email}
              onChange={(e)=> setEmail(e.target.value) }
            />
            <button className='main_inputBtn' onClick={()=> openModal()} >
              Me Contacter
            </button>
          </div> */}
        </div>
      </div>
      <div className="block" >
        <FadeInSection >
        <div className='mainFeatures' >
          <div className='mainFeature' >
            <img src={img1} alt='isoImg' className='mainFeatureImg' />
            <span className='mainFeatureDesc' >QUALITÉ & AMÉLIORATION CONTINUE</span>
            <span className='mainFeatureIso' >ISO 9001:2015</span>
          </div>
          <div className='mainFeature' >
            <img src={img2} alt='isoImg' className='mainFeatureImg' />
            <span className='mainFeatureDesc' >SANTÉ & SÉCURITÉ AU TRAVAIL</span>
            <span className='mainFeatureIso' >ISO 45001:2018</span>
          </div>
          <div className='mainFeature' >
            <img src={img3} alt='isoImg' className='mainFeatureImg' />
            <span className='mainFeatureDesc' >PROTECTION DE L'ENVIRONNEMENT</span>
            <span className='mainFeatureIso' >ISO 14001:2015</span>
          </div>
        </div>
        </FadeInSection>
      </div>
      <div className="block" >
        <FadeInSection >
        <div className='msg' >
          <span>
            Notre solution de Management QSE allie l'intelligence artificielle et la simplicité d'utilisation pour vous offrir un avantage concurrentiel unique. Grâce à notre plateforme intuitive et digitale, vous pouvez automatiser vos processus, améliorer la conformité réglementaire et optimiser les performances de votre entreprise. Bénéficiez d'une visibilité complète sur vos opérations QSE, identifiez rapidement les opportunités d'amélioration et prenez des décisions éclairées en temps réel. Notre solution vous permet de transformer votre approche QSE, en réduisant les coûts, en minimisant les risques et en renforçant votre réputation en matière de durabilité. Faites évoluer votre gestion QSE vers l'avenir avec notre allié technologique.
          </span>
          <span className='authName' >
            Younes Laassouli
          </span>
          <span className='authInfo' >
            CEO OF E-QUALITY ENGINEERING /BETTERFLY SOLUTIONS
          </span>
          <div className='authImg' />
        </div>
        </FadeInSection>
      </div>
      <div className="block" id='preview' >
        <div className='preview' >
          <ul className='prev_categories' >
            {prev_menu.map((item,index)=>{
              return(
                <li 
                  key={index} 
                  onClick={()=> selectCat(item.name, index) }
                  className={item.name === selectedCat ? "selctedCat prev_cat" : "prev_cat"}  
                >
                  {item.name}
                </li>
              )
            })}
          </ul>
          {/* <div className='pc-phoneImg' >
            <img
              src={pcImg}
              alt="pcImg"
              className='pcMockup'
            />
            <div className='pc_content' >
              {
                selectedCat === "Voir plus"
                ? <button className='prev_seeMore btn1' onClick={()=> openModal()} >Contactez nous</button>
                :
                <img 
                  id={selectedCat+'_pc'}
                  src={prevMenu_pcImgs[selectedCat]} 
                  alt='prevPcImg' 
                  className='pc_contentImg' 
                  loading="lazy"
                  onLoad={()=> setImgLoad(true) }
                />
              }
              {!imgLoad && <div className='loader' /> }
            </div>
            <select className='selectPhonePrev' onChange={(e)=> setSelectedCat(e.target.value) } >
              <option value="Plans d'actions" selected={"Plans d'actions" === selectedCat} >Plans d'actions</option>
              <option value="Management des risques" selected={"Management des risques" === selectedCat} >Management des risques</option>
              <option value="Objectifs & Indicateurs" selected={"Objectifs & Indicateurs" === selectedCat} >Objectifs & Indicateurs</option>
              <option value="Analyse SWOT & BPI" selected={"Analyse SWOT & BPI" === selectedCat} >Analyse SWOT & BPI</option>
              <option value="Administration" selected={"Administration" === selectedCat} >Administration</option>
            </select>
            <div className='phoneImg' >
              <img
                src={phoneImg}
                alt="phoneImg"
                className='phoneMockup'
                onClick={()=> console.log(selectedCat) }
              />
              {
                selectedCat === "Voir plus"
                ? <div/>
                :              
                <img 
                  id={selectedCat+'_phone'}
                  src={prevMenu_phoneImgs[selectedCat]} 
                  alt='prevPhoneImg' 
                  className='phone_contentImg' 
                  loading="lazy"
                />
              }
            </div>
          </div> */}

          <div className='pc-phoneImg' >
            <img
              src={pcImg}
              alt="pcImg"
              className='pcMockup'
            />
            <div className='pc_content' >
              {
                selectedCat === "Voir plus"
                ? <button className='prev_seeMore btn1' onClick={()=> openModal()} >Contactez nous</button>
                : prevMenu_pcImgs_2[selectedCatIndex].type === "img" ?
                <div>
                  <img 
                    id={selectedCat+'_pc'}
                    src={prevMenu_pcImgs_2[selectedCatIndex].url} 
                    alt='prevPcImg' 
                    className='pc_contentImg' 
                    loading="lazy"
                    onLoad={()=> setImgLoad(true) }
                  />
                  {!imgLoad && <div className='loader' /> }
                </div>
                :
                <div>
                  <video onLoadedData={()=> setImgLoad(true)} src={prevMenu_pcImgs_2[selectedCatIndex].url} autoPlay muted className='pc_contentImg' />
                  {!imgLoad && <div className='loader' /> }
                </div>
              }
            </div>
            <select className='selectPhonePrev' onChange={(e)=> selectCatMobile(e.target.value) } >
              <option id='0' value="Tableau de bord" selected={"Tableau de bord" === selectedCat} >Tableau de bord</option>
              <option id='1' value="Plans d'actions" selected={"Plans d'actions" === selectedCat} >Plans d'actions</option>
              <option id='2' value="Management des risques" selected={"Management des risques" === selectedCat} >Management des risques</option>
              <option id='3' value="Objectifs & Indicateurs" selected={"Objectifs & Indicateurs" === selectedCat} >Objectifs & Indicateurs</option>
              <option id='4' value="Analyse SWOT & BPI" selected={"Analyse SWOT & BPI" === selectedCat} >Analyse SWOT & BPI</option>
              <option id='5' value="Administration" selected={"Administration" === selectedCat} >Administration</option>
            </select>
            <div className='phoneImg' >
              <img
                src={phoneImg}
                alt="phoneImg"
                className='phoneMockup'
                onClick={()=> console.log(selectedCat) }
              />
              {
                selectedCat === "Voir plus"
                ? <div/>
                : prevMenu_phoneImgs_2[selectedCatIndex].type === "img" ?         
                <img 
                  id={selectedCat+'_phone'}
                  src={prevMenu_phoneImgs_2[selectedCatIndex].url} 
                  alt='prevPhoneImg' 
                  className='phone_contentImg' 
                  loading="lazy"
                />
                :
                <video onLoad={()=> setImgLoad(true) } src={prevMenu_phoneImgs_2[selectedCatIndex].url} autoPlay muted className='phone_contentImg' />
              }
            </div>
          </div>
        </div>
      </div>
      <div className="block" id='features' >
        <FadeInSection >
        <h1 className='title' >
          Fonctionnalités
        </h1>
        </FadeInSection>

        <FadeInSection >
        <div className='mainFeature_card' >
          <h2 className='featureTitle' >Gestion Multi-Standards, Multi-Utilisateurs, Multi-Processus et Multi-Site</h2>
          <span className='featureText' >Betterfly offre une flexibilité inégalée pour adapter le système à vos besoins spécifiques, quels que soient le nombre de standards, d'utilisateurs, de processus ou de sites que vous gérez</span>
        </div>
        </FadeInSection>
        <div className='features' >
          {
            features.map((item,index)=>{
              return(
                <div key={index} className='feature_card' >
                  <h2 className='featureTitle' >{item.title}</h2>
                  <span className='featureText' >{item.text}</span>
                </div>
              )
            })
          }
        </div>
      </div>
      
      {/* <div className="block" style={{marginBottom: '32px', paddingBottom: '32px'}} id='advantage' >
        <span className='title' >
          Pourquoi Betterfly?
        </span>
        <span className='subTitle' >
          Choisir Betterfly, c'est opter pour une multitude d'avantages qui transformeront votre gestion QSE :
        </span>
        <FadeInSection >
        <div className='advantageCard' >
          <div className='advantageCardTitle advantageCardTitle_left' >
            <span>1. Connectivité</span>
          </div>
          <span className='advantageText' >Grâce à sa capacité à se connecter aux bases de données externes, Betterfly vous permet d'automatiser le calcul de vos indicateurs de performance. Vous gagnez du temps et améliorez la précision de vos analyses.</span>
        </div>
        </FadeInSection>

        <FadeInSection >
        <div className='advantageCard' >
          <div className='advantageCardTitle advantageCardTitle_right' >
            <span>2. Visualisation des données</span>
          </div>
          <span className='advantageText' >Betterfly transforme automatiquement vos informations en tableaux de bord visuels avec des graphiques. Vous obtenez ainsi une vue claire et détaillée de vos performances QSE, vous aidant à prendre des décisions éclairées.</span>
        </div>
        </FadeInSection>
        
        <FadeInSection >
        <div className='advantageCard' >
          <div className='advantageCardTitle advantageCardTitle_left' >
            <span>3. Automatisation avancée</span>
          </div>
          <span className='advantageText' >Avec Betterfly, les indicateurs de performance sont calculés automatiquement, vous offrant une visibilité accrue sur les performances de votre système de management QSE.</span>
        </div>
        </FadeInSection>
        
        <FadeInSection >
        <div className='advantageCard' >
          <div className='advantageCardTitle advantageCardTitle_right' >
            <span>4. Personnalisation grâce à l'IA</span>
          </div>
          <span className='advantageText' >L'utilisation de l'intelligence artificielle permet à Betterfly de générer des rapports et des tableaux de bord personnalisés. Quelle que soit la question que vous posez à la solution, l'IA analyse les données pour vous fournir une réponse claire sous forme de graphique.</span>
        </div>
        </FadeInSection>
        
      </div> */}
      
      <FadeInSection >
      <div className='block' style={{marginBottom: '32px'}} >
        <h1 className='title' >
          Pourquoi Betterfly?
        </h1>
        <h3 className='subTitle' >
          Choisir Betterfly, c'est opter pour une multitude d'avantages qui transformeront votre gestion QSE :
        </h3>
      </div>
      </FadeInSection>
      <div className='advantageCardBlock' id='advantage' >
        <div>
          <div className='advantageCard_V2' >
            <div style={{height: '10px', width: '100%'}} ref={ref1_top} />
            <div style={{display: 'flex', flexDirection: 'column'}} >
              <h2 className='advantageCardTitle_V2' >Connectivité</h2>
              <span className='advantageCardText_V2' >Grâce à sa capacité à se connecter aux bases de données externes, Betterfly vous permet d'automatiser le calcul de vos indicateurs de performance. Vous gagnez du temps et améliorez la précision de vos analyses.</span>
            </div>
            <div style={{height: '10px', width: '100%'}} ref={ref1_bottom} />
          </div>
          <div className='advantageCard_V2' >
            <div style={{height: '10px', width: '100%'}} ref={ref2_top} />
            <div style={{display: 'flex', flexDirection: 'column'}} >
              <h2 className='advantageCardTitle_V2' >Visualisation des données</h2>
              <span className='advantageCardText_V2' >Betterfly transforme automatiquement vos informations en tableaux de bord visuels avec des graphiques. Vous obtenez ainsi une vue claire et détaillée de vos performances QSE, vous aidant à prendre des décisions éclairées.</span>
            </div>
            <div style={{height: '10px', width: '100%'}} ref={ref2_bottom} />
          </div>
          <div className='advantageCard_V2' >
            <div style={{height: '10px', width: '100%'}} ref={ref3_top} />
            <div style={{display: 'flex', flexDirection: 'column'}} >
              <h2 className='advantageCardTitle_V2' >Automatisation avancée</h2>
              <span className='advantageCardText_V2' >Avec Betterfly, les indicateurs de performance sont calculés automatiquement, vous offrant une visibilité accrue sur les performances de votre système de management QSE.</span>
            </div>
            <div style={{height: '10px', width: '100%'}} ref={ref3_bottom} />
          </div>
          <div className='advantageCard_V2' >
            <div style={{height: '10px', width: '100%'}} ref={ref4_top} />
            <div style={{display: 'flex', flexDirection: 'column'}} >
              <h2 className='advantageCardTitle_V2' >Personnalisation grâce à l'IA</h2>
              <span className='advantageCardText_V2'  >L'utilisation de l'intelligence artificielle permet à Betterfly de générer des rapports et des tableaux de bord personnalisés. Quelle que soit la question que vous posez à la solution, l'IA analyse les données pour vous fournir une réponse claire sous forme de graphique.</span>
            </div>
            <div style={{height: '10px', width: '100%'}} ref={ref4_bottom} />
          </div>
        </div>
        <div className='advantageCardImgContainer' >
          <div id='advantageImg1' className='advantageCardImgBox' >
            <img src={connectivity} alt='testPic' className="advantageCardImg" />
          </div>
          <div id='advantageImg2' className='advantageCardImgBox' >
            <img src={visualisation} alt='testPic' className="advantageCardImg" />
          </div>
          <div id='advantageImg3' className='advantageCardImgBox' >
            <img src={automatisation} alt='testPic' className="advantageCardImg" />
          </div>
          <div id='advantageImg4' className='advantageCardImgBox' >
            <img src={ai} alt='testPic' className="advantageCardImg" />
          </div>
        </div>
      </div>

      <FadeInSection >
        <div className="block" >
          <h1 className='title' >
            Entreprises ayant bénéficié de la solution Betterfly
          </h1>
        </div>
      </FadeInSection>

      <div className="block" >
        {/* <FadeInSection >
        <span className='title' >
          Entreprises ayant bénéficié de la solution Betterfly
        </span>
        </FadeInSection> */}
        <Marquee autoFill={true} pauseOnHover={true} speed={80} style={{overflowY: 'hidden', marginTop: '16px'}} >
          {references.map((it)=>
            <div className="flip" key={it.id} >
              <div className="flip-content">
                <div className="flip-front">
                  <div className="frontImgContainer" key={it.id} >
                    <img src={it.img} className='frontImg' alt='consulting' />
                  </div>
                </div>
                <div className="flip-back">
                  <div className='backContainer' >
                    <div className="backImgContainer" key={it.id} >
                      <img src={it.img} className='backImg' alt='consulting' />
                    </div>
                    <div >
                      <span style={{color: "#888"}} >Année: </span>{it.year}
                    </div>
                    <div style={{marginTop: '12px'}} >
                      <span style={{color: "#888"}} >Standard: </span>{it.iso}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Marquee>
      </div>

      <div className='block' >
        <FadeInSection >
        <div className='articlesBlockTitleContainer' >
          <h1 className='articlesBlockTitle' >Articles</h1>
          <a className='articlesSeeAllText' href='articles/' >Voir tous</a>
        </div>
        </FadeInSection>
        <div className='main_articles' >
          {articles.map((it, index)=> articleContainer(it, index) )}
        </div>
      </div>

      { isModalOpen && <ContactUsModal close={closeModal} isOpen={isModalOpen} defaultEmail={email} /> }
    </div>
  )
}
