import styles from '../styles/articles.module.css'; 
import React, {useState, useEffect, useContext} from 'react';
import { FiChevronRight } from 'react-icons/fi';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import 'moment/locale/fr';
import {Helmet} from "react-helmet";

import { UserContext } from '../Contexts/UserContext';

export default function Articles() {

  const {uid} = useContext(UserContext);
  const [articles, setArtciles] = useState([]);

  const getData = ()=>{
    const db = firebase.database().ref("articles");
    db.once('value',function(snap){
      let retArray = [];
      if(snap.val){
        snap.forEach(function(child){
          let items = child.val();
          retArray.push(items);
        })
      }
      setArtciles(retArray.reverse());
    })
  }

  const articleContainer = (item)=>(
    <div className={styles.article} key={item.id} >
      <div className={styles.articleImgContainer} >
        <img src={item.picture} className={styles.articleImg} alt='article background' />
      </div>
      <div className={styles.articleBodyContainer} >
        <div className={styles.articleTitle} >
          <span>{item.title}</span>
        </div>
        <div className={styles.articleFooter} >
          <span className='main_articleDate' >{moment(item.date).format('LLL')}</span>
          <a href={`/article/${item.id}`} className={styles.articleBtn} >
            <span className={styles.articleBtnText} >Lire plus</span>
            <FiChevronRight size={18} color='#888' />
          </a>
        </div>
      </div>
    </div>
  )

  useEffect(()=>{
    if(uid){
      getData();
    }
  },[uid])

  return (
    <div className={styles.articles} >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Betterfly | Articles</title>

        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-725193655"></script>
        <script type="text/javascript">{`          
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-725193655');
        `}</script>
      </Helmet>
      {articles.map((it)=> articleContainer(it) )}
      
    </div>
  )
}
