import './App.css';
import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { UserProvider } from './Contexts/UserContext';

import { Header, Footer } from './Components/Nav';
import Main from './Components/Main';
import Articles from './Components/Articles';
import Article from './Components/Article';
import AddArticle from './Components/AddArticle';
import Admin from './Components/Admin';
import EditArticle from './Components/EditArticle';
import ContactUs from './Components/ContactUs';
import NotFound from './Components/NotFound';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main/>,
    errorElement: <NotFound />,
  },
  {
    path: "articles/",
    element: <Articles/>,
  },
  {
    path: "article/:articleId",
    element: <Article/>,
  },
  {
    path: "add-article/",
    element: <AddArticle/>,
  },
  {
    path: "admin/",
    element: <Admin/>,
  },
  {
    path: "edit-article/:articleId",
    element: <EditArticle/>,
  },
  {
    path: "contact/",
    element: <ContactUs/>,
  },
  // {
  //   path: "*",
  //   element: <NotFound/>
  // }
]);

function App() {

  return (
    <div className="App">
      <UserProvider>
        <Header/>
        <RouterProvider router={router} />
        <Footer/>
      </UserProvider>
    </div>
  );
}

export default App;
