import '../styles/nav.css';
import React, {useState, useEffect} from 'react';
import ContactUsModal from './ContactUsModal';
import { FiFacebook, FiLinkedin } from 'react-icons/fi';

export function Header(){

  const [offset, setOffset] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onScroll = () => setOffset(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", onScroll, { passive: true });
    }
  }, []);
  useEffect(() => {
    if(offset >= 100){
      if(window.innerWidth > 642){
        document.getElementsByClassName("nav")[0].style.padding = "12px 60px";
      }
      document.getElementsByClassName("nav")[0].style.boxShadow = "0 4px 12px rgba(0,0,0,0.15)";
      document.getElementsByClassName("nav")[0].style.backgroundColor = "#D9F1FE";
    }else{
      if(window.innerWidth > 642){
        document.getElementsByClassName("nav")[0].style.padding = "18px 60px";
      }else{
        document.getElementsByClassName("nav")[0].style.padding = "12px 20px";
      }
      document.getElementsByClassName("nav")[0].style.boxShadow = "none";
      document.getElementsByClassName("nav")[0].style.backgroundColor = "transparent";
    }
  }, [offset]);

  const openModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsModalOpen(true);
  }
  const closeModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsModalOpen(false);
  }

  return(
    <div className="nav" >
      <div className="nav_logoContainer" >
        <a href='/' >
          <img src="https://res.cloudinary.com/m-l-e/image/upload/v1686831849/betterfly/betterfly.png" alt='betterfly' className='nav_logo' />
        </a>
      </div>
      <ul className='headerNav_btns' >
        <li>
          <a href='/#home' className='navBtnText' >Home</a>
        </li>
        <li>
          <a href='/#preview' className='navBtnText' >Aperçu</a>
        </li>
        <li>
          <a href='/#features' className='navBtnText' >Fonctionnalités</a>
        </li>
        <li>
          <a href='/#advantage' className='navBtnText' >Avantages</a>
        </li>
        <li>
          <a href='articles/' className='navBtnText' >Articles</a>
        </li>
        <li >
          <button className="navContactUsBtn" onClick={()=> openModal() } >Contactez nous</button>
        </li>
        <li >
          <a className="navBookBtn" href='https://calendly.com/younes-laassouli/demonstration-betterfly' target='_blank' rel="noreferrer" style={{color: '#fff', textDecoration: 'none'}} >
            Rendez-vous en ligne
          </a>
        </li>
      </ul>
      { isModalOpen && <ContactUsModal close={closeModal} isOpen={isModalOpen} defaultEmail={null} /> }
    </div>
  )
}


export function Footer(){

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsModalOpen(true);
  }
  const closeModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsModalOpen(false);
  }

  return(
    <div className='footer' >
      { isModalOpen && <ContactUsModal close={closeModal} isOpen={isModalOpen} defaultEmail={null} /> }
      <div>
        <div className="footer_logoContainer" >
          <img src="https://res.cloudinary.com/m-l-e/image/upload/v1686831849/betterfly/betterfly.png" alt='betterfly' className='footer_logo' />
        </div>
        <div style={{display: 'flex', margin: '22px 0'}} >
          <a className='RS RS_fb' target='_blank' rel="noreferrer" href='https://www.facebook.com/betterflysolutions?mibextid=ZbWKwL' >
            <FiFacebook size={22} />
          </a>
          <a className='RS RS_Linkedin' target='_blank' rel="noreferrer" href='https://www.linkedin.com/company/betterfly-solutions/' >
            <FiLinkedin size={22} />
          </a>
          <a className='RS RS_Linkedin' target='_blank' rel="noreferrer" href='https://www.linkedin.com/in/younes-laassouli-bb2a8758/' >
            <FiLinkedin size={22} />
          </a>
        </div>
        <h4 className='msgUs' onClick={()=> openModal() } >Envoyer nous un message</h4>
        <h4 style={{marginBottom: '0'}} >Nous contacter:</h4>
        <ul className='contactDetails' >
          <li>
            <h4 style={{margin: '0', marginTop: '12px'}} ><a className='link' target='_blank' rel="noreferrer" href="https://www.google.com/maps/search/25,+Boulevard+d'Anfa,+Rue+de+l'arrondissement+Sidi+Belyout,+Casablanca+20250,+Morocco/@33.595472,-7.627586,16z?hl=en&entry=ttu" >2 Rue Abou Hassan Alachaari 4ème étage – Bd Anfa - Casablanca</a></h4>
          </li>
          <li>
            <h4 style={{margin: '0', marginTop: '12px'}} >Tél : <a className='link' href="tel:+212675021809">+212 675021809</a></h4>
          </li>
          <li>
            <h4 style={{margin: '0', marginTop: '12px'}} >Email : <a className='link' href='mailto:contact@equality.ma' >contact@equality.ma</a></h4>
          </li>
        </ul>        
      </div>
      <iframe title='maps view' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6646.757809525682!2d-7.627586!3d33.595472!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7d28e25e07ea3%3A0x9a8ab1a3036cbce3!2s25%20Bd%20d&#39;Anfa%2C%20Casablanca%2020250!5e0!3m2!1sen!2sma!4v1687192060202!5m2!1sen!2sma" width="100%" height="300" style={{border: '0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}