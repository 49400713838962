import styles from '../styles/addArticle.module.css'; 
import React, { useState, useEffect } from 'react';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import firebase from 'firebase/app';
import 'firebase/database';
import "firebase/storage";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const config = {
  toolbar: {
    items: [
      'bold',
      'italic',
      '|',
      'bulletedList',
      'numberedList',
      'indent',
      'outdent',
      '|',
      'heading',
      '|',
      'undo',
      'redo',
      '|',
      'link',
      'insertTable'
    ]
  }
}

const TextEditor = ({ onSubmit }) => {
  let navigate = useNavigate(); 
  const {articleId} = useParams();

  const [body, setBody] = useState("");
  const [title, setTitle] = useState("");
  const [imageAsFile, setImageAsFile] = useState('');
  const [imageAsUrl, setImageAsUrl] = useState({imgUrl: ''});
  const [article, setArticle] = useState({});

  const storage = firebase.storage();

  ClassicEditor.defaultConfig = config;

  const getArticle = ()=>{
    const db = firebase.database().ref("articles/"+articleId); 
    db.once('value',function(snap){
      if(snap.val){
        setArticle(snap.val());
      }
    })
  } 

  useEffect(()=>{
    getArticle();
  },[])

  useEffect(()=>{
    if(Object.keys(article).length > 0){
      setTitle(article.title);
      setImageAsUrl({imgUrl: article.picture});
      setBody(article.text);
    }
  },[article])

  const handleFireBaseUpload = e => {
    e.preventDefault()
    console.log('start of upload')
    // async magic goes here...
    if(imageAsFile === '') {
      console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
    }
    const uploadTask = storage.ref(`/images/${imageAsFile.name}`).put(imageAsFile)
    //initiates the firebase side uploading 
    uploadTask.on('state_changed', 
    (snapShot) => {
      //takes a snap shot of the process as it is happening
      console.log(snapShot)
    }, (err) => {
      //catches the errors
      console.log(err)
    }, () => {
      // gets the functions from storage refences the image storage in firebase by the children
      // gets the download url then sets the image from firebase as the value for the imgUrl key:
      storage.ref('images').child(imageAsFile.name).getDownloadURL()
      .then(fireBaseUrl => {
        setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}));
        setImageAsFile("");
      })
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const post = firebase.database().ref("articles/"+articleId);
    post.update({
      title,
      text: body,
      date: new Date().getTime(),
      picture: imageAsUrl.imgUrl
    }).then(()=>{
      console.log("updated");
      navigate('/');
    })
  }

  return (
    // <form onSubmit={handleSubmit} >
    <div >
      <input
        onChange={(event)=> setTitle(event.target.value) }
        value={title}
        placeholder="Titre de l'article"
        className={styles.newArticleTitleInput}
      />
      <div className={styles.imgInput} >
        { 
          <div style={{display: 'flex', justifyContent: 'space-between'}} >
            <input
              label="Image"
              placeholder="Choose image"
              accept="image/png,image/jpeg"
              type="file"
              onChange={(e) => {
                setImageAsFile(e.target.files[0]);
                setImageAsUrl({imgUrl: ''});
              }}
            />
            <button className={styles.uploadImgBtn} onClick={(e)=> handleFireBaseUpload(e) } >upload image</button>
          </div>
        }
        {imageAsFile !== '' && <img alt="preview" src={URL.createObjectURL(imageAsFile)} className={styles.imgPreview} />}
        {imageAsUrl.imgUrl && imageAsUrl.imgUrl.length > 0 && <img alt="preview" src={imageAsUrl.imgUrl} className={styles.imgPreview} /> }
      </div>
      <CKEditor
        editor={ClassicEditor}
        onChange={(event, editor) => {
          const data = editor.getData();
          setBody(data)
        }}
        data={body}
      />
      <div className={styles.submitBtnContainer} >
        <button type='submit' className={styles.submitBtn} onClick={(e)=> handleSubmit(e) } >Modifier</button>
      </div>
    </div>
  )
}

export default function EditArticle() {

  const location = useLocation();
  let navigate = useNavigate(); 

  useEffect(()=>{
    if(location.state?.fromAdmin){
      console.log("from admin");
    }else{
      navigate('/');
    }
  },[location])

  return (
    <div className={styles.addArticle} >
      <TextEditor/>
    </div>
  )
}
