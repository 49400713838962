import '../styles/modal.css';
import React, {useState, useEffect} from 'react';
import { FiCheck } from "react-icons/fi";
import axios from 'axios';

import axiosMsg from './axiosMsg';

export default function ContactUsModal({children, isOpen, close, defaultEmail}) {

  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [msgSended, setMsgSended] = useState(false);

  function isValidEmail(email) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;    
    return emailPattern.test(email);
  }

  const sendMail = async ()=>{
    let text = ` ${prenom} ${nom} a envoyé un message : <br />
      ${msg}
      <br />
      <br />
      <br />
      Prenom: ${prenom} <br />
      Nom: ${nom} <br />
      Téléphone: ${phone} <br />
      Email: ${email}
    `

    await axios.post(
      'https://znpbi0h08h.execute-api.eu-west-3.amazonaws.com/default/SendEmailPython',
      {
        "to_address": "younes.laassouli@equality.ma",
        "subject": `Betterfly Site | Contact from ${email}`,
        "body": text,
        "from_address": "smi@betterfly.solutions",
        "body_html": axiosMsg(text)
      }
    ).then(function(response) {
      if (response.status !== 200) {
        alert(`Looks like there was a problem. Status code: ${response.status}`);
        return;
      }
      console.log(response);
      setMsgSended(true);
    })
    .catch(function (error) {
      alert("Fetch error: " + error);
    })
  }

  const send = ()=>{
    if(prenom.length <=2){
      setErrorMsg("Prenom invalide : Le prenom doit comporter au moins 3 caractères");
    }else if(nom.length <= 2){
      setErrorMsg("Nom invalide : Le nom doit comporter au moins 3 caractères");
    }else if(!isValidEmail(email)){
      setErrorMsg("Email invalide");
    }else if(phone.length <= 9){
      setErrorMsg("Numéro de téléphone invalide");
    }else{
      setErrorMsg(null);
      sendMail();
    }
  }

  useEffect(()=>{
    if(defaultEmail){
      setEmail(defaultEmail);
    }
  },[defaultEmail])

  if(isOpen && msgSended){
    return(
      <div className='modal' >
        <div className='modalContainer' >
          <div className='modalHeader' onClick={()=> close() } >
            <span className='modalTitle' >Contactez nous</span>
            <svg fill="none" stroke="currentColor" className="modal_X" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
          </div>
          <p className='msgSendedTitle' >
            Message reçu! Vous recevrez une réponse de notre équipe dans les plus brefs délais.
          </p>
          <p className='msgSendedTitle' >Merci.</p>
        </div>
        <div className='modal_bg' onClick={()=> close() } />
      </div>
    )
  }

  if(isOpen){
    return (
      <div className='modal' >
        <div className='modalContainer' >
          <div className='modalHeader' onClick={()=> close() } >
            <span className='modalTitle' >Contactez nous</span>
            <svg fill="none" stroke="currentColor" className="modal_X" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
          </div>
          <div className='modalContent' >
            <p className='mobileTitle' >
              Contactez notre équipe d'experts afin de comprendre comment Betterfly peut transformer votre entreprise.
            </p>
            <div className='fields' >
              { errorMsg &&
                <div className='errorView' >
                  <span className='errorMsg' >{errorMsg}</span>
                </div>
              }
              <div className='nameField' >
                <div className='field' >
                  <label className='fieldLabel' >Prénom *</label>
                  <input
                    type='text'
                    placeholder='Prénom'
                    className='fieldInput'
                    value={prenom}
                    onChange={(e)=> setPrenom(e.target.value) }
                  />
                </div>
                <div className='field' >
                  <label className='fieldLabel' >Nom *</label>
                  <input
                    type='text'
                    placeholder='Nom'
                    className='fieldInput'
                    value={nom}
                    onChange={(e)=> setNom(e.target.value) }
                  />
                </div>
              </div>
              <div className='field' >
                <label className='fieldLabel' >Email *</label>
                <input
                  type='text'
                  placeholder='Ex: name@company.com'
                  className='fieldInput'
                  value={email}
                  onChange={(e)=> setEmail(e.target.value) }
                />
              </div>
              <div className='field' >
                <label className='fieldLabel' >Téléphone *</label>
                <input
                  type='text'
                  placeholder='Ex: 0612340000'
                  className='fieldInput'
                  value={phone}
                  onChange={(e)=> setPhone(e.target.value) }
                />
              </div>
              <div className='field' >
                <label className='fieldLabel' >Questions/Message</label>
                <textarea
                  type='text'
                  placeholder='Questions/Message'
                  className='fieldInput'
                  value={msg}
                  rows={3}
                  onChange={(e)=> setMsg(e.target.value) }
                />
              </div>
              <button className="contactBtn" onClick={()=> send() } >
                Me Contacter
              </button>
            </div>
            <div className='info' >
              <p className='infoTitle' >
                Contactez notre équipe d'experts afin de comprendre comment Betterfly peut transformer votre entreprise.
              </p>
              <p className='infoSubTitle' >Décrivez les objectifs de votre entreprise et découvrez comment Betterfly peut contribuer à sa croissance.</p>
              <div style={{display: 'flex', marginBottom: '12px'}} >
                <FiCheck size={24} style={{width: '24px', marginRight: '8px'}} color='#2A8A0C' />
                <span className='infoText' >
                  Obtenez les conseils d'une équipe d'experts à l'écoute de vos besoins commerciaux
                </span>
              </div>
              <div style={{display: 'flex', marginBottom: '12px'}} >
                <FiCheck size={24} style={{width: '24px', marginRight: '8px'}} color='#2A8A0C' />
                <span className='infoText' >
                  Découvrez le fonctionnement de notre plateforme
                </span>
              </div>
              <div style={{display: 'flex'}} >
                <FiCheck size={24} style={{width: '24px', marginRight: '8px'}} color='#2A8A0C' />
                <span className='infoText' >
                  Découvrez comment Betterfly a aidé les entreprises de votre secteur à se transformer
                </span>
              </div>
            </div>
          </div>
          <p className='infoBelowTitle' >
            Remplissez simplement le formulaire ou appelez-nous au +212 675021809.
          </p>
        </div>
        <div className='modal_bg' onClick={()=> close() } />
      </div>
    )
  }else{
    return (
      null
    )
  }

}