import React from 'react';
import { useNavigate } from 'react-router-dom';
import ContactUsModal from './ContactUsModal';

export default function ContactUs() {

  const navigate = useNavigate();

  const closeModal = ()=>{
    navigate("/");
  }

  return (
    <div style={{height: '80vh'}} >
      <ContactUsModal close={closeModal} isOpen={true} defaultEmail={""} />
    </div>
  )
}
